
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import PageButton from './PageButton.vue';

@Component({
	components: {
		PageButton,
	},
})
export default class PageIndicators extends Vue {
	@Prop({ default: () => [] }) public pageNotes!: string[][];
}
