
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import uuid from 'uuid';
import { isTouchDevice } from '@/util/isTouchDevice';

@Component({
	components: {},
})
export default class PageButton extends Vue {
	@Prop({ default: '' }) public info!: string;
	@Prop({ default: '' }) public path!: string;
	public id = uuid.v4();
	public isTouchDevice = isTouchDevice;
}
