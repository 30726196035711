
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
	components: {},
})
export default class TitlePageIndex extends Vue {
	@Prop({ default: 1 }) public currentPage!: number;
	@Prop({ default: 1 }) public totalPages!: number;
}
